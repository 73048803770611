// import metadata from './metadata.json';
// import json6 from './json6.json';

export const getItem = (productId, pages) => {
  // return json6;
  const urlPages = pages ? '?pages=' + pages : '';
  return fetch(`/api/xml/${productId}/json` + urlPages).then(response =>
    response.json()
  );
};

export const getMetadatas = itemId => {
  // return metadata;
  return fetch(`/api/items/${itemId}/metadata`).then(response =>
    response.json()
  );
};

export const getServerImage = (itemId, fileId) => {
  return fetch(`/api/items/${itemId}/file/${fileId}`).then(r => r.blob());
};

export const resetServerImage = (itemId, fileId) =>
  fetch(`/api/items/${itemId}/file/${fileId}/reset`, {
    method: 'POST',
    credentials: 'same-origin'
  }).then(r => r.json());

export const postLog = msg => {
  try {
    fetch('/api/log', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        type: 'info',
        msg: JSON.stringify(msg)
      })
    });
  } catch {}
};

export const postItem = async (url, data, img) => {
  const postOptions = {
    method: 'POST',
    credentials: 'same-origin',
    headers: img || {
      'Content-type': 'application/json',
      Accept: 'application/json'
    },
    body: img ? data : JSON.stringify(data)
  };

  try {
    postLog({
      itemID: url ? url.split('/')[3] : '-',
      imageHash: url ? url.split('/')[5] : '-',
      imageName: (data && data.name) || '-',
      postUrl: url || '-',
      img: img || '-',
      timestamp: new Date()
    });
  } catch {}

  return fetch(url, postOptions).then(response => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject();
  });
};

export const fetchItemStatus = async itemId => {
  return fetch(`/api/items/${itemId}/status`)
    .then(async response => {
      let result = null;
      if (response.ok) {
        result = await response.json();
      }
      return result;
    })
    .catch(() => null);
};
