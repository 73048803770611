import * as R from 'ramda';
import * as actionGeneral from '../action/general';
import normalizeServerItem from '../schema/server-item';
import { warnClose } from '../app';
import { put, select, delay } from 'redux-saga/effects';
import { getItem, postItem } from '../api';
import { removerChavesObjeto, isFix } from '../utils/calculosGerais';

export function* postMetadata({ payload: { id, attempt = 1 } }) {
  const { item, library, serverImages } = yield select();

  const sendLibrary = removerChavesObjeto(library, [
    'description',
    'currency',
    'shape',
    'style',
    'textarea'
  ]);

  const { imagesToUpload: imagesNotUploaded, failToUpload } = serverImages;

  if (!item.finished || isFix()) {
    const postUrl = `/api/items/${id}/metadata`;
    let err = false;
    const metadata = {
      id: new Date().getTime(),
      state: {
        item,
        library: sendLibrary,
        imagesToUpload: {
          notUploaded: imagesNotUploaded,
          failToUpload
        }
      }
    };

    // eslint-disable-next-line
    yield postItem(postUrl, metadata).catch(() => (err = true));
    if (err) {
      if (attempt > 1) {
        attempt--;
        yield delay(1000 * 15);
        yield postMetadata({ payload: { id, attempt } });
      }
    }
  }
}

export function* postMetadataFormat({
  payload: { id, productFormat, pageFormat }
}) {
  if (!pageFormat) {
    yield put(actionGeneral.sagaPostMetadata(id));
    return;
  }

  const { item, ui, library } = yield select();
  if (!item.finished || isFix()) {
    const postUrl = `/api/items/${id}/metadata`;
    let err = false;
    const metadata = { id: new Date().getTime(), state: { item, ui, library } };

    const data = yield getItem(getPosterItemId(productFormat), null);
    const { entities: normalizedData } = normalizeServerItem(data);

    const fatorAjuste = getFatorAjuste(pageFormat, item.pageFormat);
    const productCode = data.settings.description.code;
    const ean = library.product[id].settings.print.ean;
    const urls = library.product[id].settings.urls;

    item.pageFormat = data.pageFormat;
    const auxPageSetIndex =
      typeof item.lastPageSetId === 'string' && item.lastPageSetId !== 0
        ? item.lastPageSetId.split('-')[1]
        : '1';
    item.lastPageSetId = productCode + '-' + auxPageSetIndex;
    item.pageSetId = productCode + '-' + item.pageSetId.split('-')[1];

    const shapes_old = { ...library.shape };

    library.description = { ...normalizedData.description };
    library.product[id] =
      normalizedData.product[Object.keys(normalizedData.product)[0]];
    library.product[id].id = id;
    library.product[id].settings.urls = urls;
    library.product[id].settings.print.ean = ean;
    library.set = normalizedData.set;
    library.shape = normalizedData.shape;
    library.style = normalizedData.style;
    library.textarea = normalizedData.textarea;

    if (library.frame || library.page)
      reajustarPosicaoFrame(
        library.frame,
        library.shape,
        shapes_old,
        library.image,
        library.source,
        fatorAjuste
      );

    if (library.text != null) reajustarTamanhoTextos(library.text, fatorAjuste);

    if (data) {
      //eslint-disable-next-line
      yield postItem(postUrl, metadata).catch(() => (err = true));
      if (err) {
      } else {
        yield put(
          actionGeneral.sagaAddServerImageUploadEnable(R.keys(library.image))
        );
        const url = window.location.origin;
        window.removeEventListener('beforeunload', warnClose);
        setTimeout(() => {
          window.location.assign(url + `?itemId=${id}`);
          window.addEventListener('beforeunload', warnClose);
        }, 1);
      }
    }
  }
}

export function* postMetadataOrientation({
  payload: { id, productFormat, pageFormat }
}) {
  if (!pageFormat) {
    yield put(actionGeneral.sagaPostMetadata(id));
    return;
  }

  const { item, ui, library } = yield select();
  if (!item.finished || isFix()) {
    const postUrl = `/api/items/${id}/metadata`;
    let err = false;
    const metadata = { id: new Date().getTime(), state: { item, ui, library } };

    const data = yield getItem(getPosterItemIdOrientation(productFormat), null);
    const { entities: normalizedData } = normalizeServerItem(data);

    const productCode = data.settings.description.code;
    const ean = library.product[id].settings.print.ean;
    const urls = library.product[id].settings.urls;

    item.pageFormat = data.pageFormat;
    const auxPageSetIndex =
      typeof item.lastPageSetId === 'string' && item.lastPageSetId !== 0
        ? item.lastPageSetId.split('-')[1]
        : '1';
    item.lastPageSetId = productCode + '-' + auxPageSetIndex;
    item.pageSetId = productCode + '-' + item.pageSetId.split('-')[1];

    const shapes_old = { ...library.shape };
    const styles_old = { ...library.style };

    library.description = { ...normalizedData.description };
    library.product[id] =
      normalizedData.product[Object.keys(normalizedData.product)[0]];
    library.product[id].id = id;
    library.product[id].settings.urls = urls;
    library.product[id].settings.print.ean = ean;
    library.set = normalizedData.set;
    library.shape = normalizedData.shape;
    library.style = normalizedData.style;
    library.textarea = normalizedData.textarea;
    library.textarea = normalizedData.textarea;
    library.revisarMudancaOrientacao = true;

    if (library.frame != null || library.page != null) {
      conversaoLayout(library, shapes_old, styles_old);
    }

    if (data) {
      //eslint-disable-next-line
      yield postItem(postUrl, metadata).catch(() => (err = true));
      if (err) {
      } else {
        yield put(
          actionGeneral.sagaAddServerImageUploadEnable(R.keys(library.image))
        );
        const url = window.location.origin;
        window.removeEventListener('beforeunload', warnClose);
        setTimeout(() => {
          window.location.assign(url + `?itemId=${id}`);
          window.addEventListener('beforeunload', warnClose);
        }, 1);
      }
    }
  }
}

const getPosterItemId = pageFormat => {
  switch (pageFormat) {
    case 'A4-P':
      return 35;
    case 'A4-R':
      return 34;
    case 'A3-P':
      return 33;
    case 'A3-R':
      return 32;
    default:
      return 33;
  }
};

const getPosterItemIdOrientation = pageFormat => {
  switch (pageFormat) {
    case 'A4-P':
      return 32;
    case 'A4-R':
      return 33;
    case 'A3-P':
      return 34;
    case 'A3-R':
      return 35;
    default:
      return 33;
  }
};

const getFatorAjuste = (pageFormat, pageFormat_old) => {
  switch (pageFormat_old) {
    case 'A3':
      switch (pageFormat) {
        case 'A4':
          return parseFloat(2 / 3);
        default:
          return 1;
      }
    case 'A4':
      switch (pageFormat) {
        case 'A3':
          return 1.5;
        default:
          return 1;
      }
    default:
      return 1;
  }
};

const obterOrigem = (frame, shapes, images, sources) => {
  const { width: shapeWidth = 0, height: shapeHeight = 0 } = shapes[
    frame.shape
  ];
  const {
    source: { image: sourceId }
  } = images[frame.image];
  const { width: imgWidth, height: imgHeight } = sources[sourceId];

  const rateW = parseFloat((shapeWidth / imgWidth).toFixed(2));
  const rateH = parseFloat((shapeHeight / imgHeight).toFixed(2));

  let { originX, originY } = [0, 0];

  if (rateW > rateH) {
    const finalHeight = imgHeight * rateW - shapeHeight;
    originX = 0;
    originY = -finalHeight / 2;
  } else {
    const finalWidth = imgWidth * rateH - shapeWidth;
    originY = 0;
    originX = -finalWidth / 2;
  }

  return [originX, originY];
};

const conversaoLayout = (
  {
    frame: frames,
    page: pages,
    shape: shapes,
    style: styles,
    image: images,
    source: sources
  },
  shapes_old,
  styles_old
) => {
  //frames
  if (frames != null) {
    Object.entries(frames).forEach(frame => {
      if (frame.length >= 1) {
        const index = Object.keys(shapes_old).indexOf(
          frame[1].shape.toString()
        );
        if (index >= 0) {
          const shapeId = Object.keys(shapes)[index];
          if (shapeId) {
            frame[1].shape = parseInt(Object.keys(shapes)[index]);
          }
        }

        const [originX, originY] = obterOrigem(
          frame[1],
          shapes,
          images,
          sources
        );

        frame[1].posX = originX;
        frame[1].posY = originY;
      }
    });
  }

  //pages
  if (pages != null) {
    const pagesArray = Object.entries(pages);
    pagesArray.forEach(page => {
      if (page.length >= 1) {
        const index = Object.keys(styles_old).indexOf(page[1].style.toString());
        if (index >= 0) {
          const styleId = Object.keys(styles)[index];
          if (styleId) {
            page[1].style = parseInt(Object.keys(styles)[index]);
          }
        }
      }
    });
  }
};

const reajustarPosicaoFrame = (
  frames,
  shapes,
  shapes_old,
  images,
  sources,
  fatorAjuste
) => {
  if (frames != null) {
    Object.keys(frames).forEach(frameId => {
      const frame = frames[frameId];

      //buscar valores do formato anterior
      const { posX: posX_old, posY: posY_old } = frame;
      const [originX_old, originY_old] = obterOrigem(
        frame,
        shapes_old,
        images,
        sources
      );
      const [originX, originY] = obterOrigemEq(
        frame,
        shapes_old,
        shapes,
        images,
        sources
      );

      const movX_old = posX_old - originX_old;
      const movY_old = posY_old - originY_old;

      const movX = movX_old * fatorAjuste;
      const movY = movY_old * fatorAjuste;

      frame.posX = originX + movX;
      frame.posY = originY + movY;
    });
  }
};

const reajustarTamanhoTextos = (texts, fatorAjuste) => {
  Object.keys(texts).forEach(textId => {
    const text = texts[textId].textOptions;
    const sizeArray = text.sizeArray;
    const newSize = text.size * fatorAjuste;

    if (sizeArray != null) {
      const nearestSize = sizeArray.reduce((a, b) => {
        return Math.abs(b - newSize) <= Math.abs(a - newSize) ? b : a;
      });

      text.size = nearestSize;
    }
  });
};

const obterOrigemEq = (frame, shapes_old, shapes, images, sources) => {
  const index = Object.keys(shapes_old).indexOf(frame.shape);
  if (index >= 0) {
    const shapeId = Object.keys(shapes)[index];
    if (shapeId) {
      frame.shape = parseInt(Object.keys(shapes)[index]);
    }
  }

  return obterOrigem(frame, shapes, images, sources);
};
