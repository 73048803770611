import './index.css';

import {
  NAV_LAYOUTS_SELECTED,
  NAV_IMAGE_SELECTED,
  NAV_NONE_SELECTED
} from '../../constant/nav';
import {
  POPUP_CONFIRM_UPDATE_APPLY_METHOD,
  POPUP_IDENTIFY,
  POPUP_IDENTIFY_EMAIL,
  POPUP_ID_CLEAR_BOOK,
  POPUP_ID_CONFIRM_REMOVE_PAGES,
  POPUP_ID_ERROR_LOAD,
  POPUP_ID_FILL_PAGES_PAGES_NEEDED,
  POPUP_ID_FILL_PAGES_PHOTOS_NEEDED,
  POPUP_ID_FINISH_BOOK,
  POPUP_ID_IMAGE_USED,
  POPUP_ID_LAYOUT,
  POPUP_ID_LOADING,
  POPUP_ID_MUTIPLE_TABS,
  POPUP_ID_NONE,
  POPUP_ID_PAGE_NOT_COMPLETE_FILLED,
  POPUP_ID_POSTER_NOT_COMPLETE_FILLED,
  POPUP_ID_REMOVE_ALL_IMAGES,
  POPUP_ID_REMOVE_IMAGES,
  POPUP_ID_REMOVE_PAGES,
  POPUP_ID_SENDING_IMAGES,
  POPUP_ID_SENDING_IMAGES_COMPLETE,
  POPUP_ID_SENDING_METADATA,
  POPUP_ID_SUBMITING,
  POPUP_ID_TOGGLE_REMOVE_PAGES,
  POPUP_ID_UNABLE_LOAD_IMAGE,
  POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT,
  POPUP_SCREEN_SIZE_ERROR,
  POPUP_ID_ABANDON_PROJECT,
  POPUP_ID_REVISAR_MUDACA_ORIENTACAO,
  POPUP_FIREFOX_ANONIMO,
  POPUP_ID_MAX_USER_SPACE,
  POPUP_ID_MAX_IMAGE_COUNT
} from '../../constant/popup';

import {
  POPUP_SCREEN_SIZE_ERROR_H3_MOBILE,
  POPUP_SCREEN_SIZE_ERROR_H3,
  POPUP_SCREEN_SIZE_ERROR_MESSAGE_MOBILE,
  POPUP_SCREEN_SIZE_ERROR_MESSAGE,
  POPUP_ID_FINISH_BOOK_TEXT,
  POPUP_FIREFOX_ANONIMO_TITLE,
  POPUP_FIREFOX_ANONIMO_MESSAGE
} from '../../constant/popup-message';

import {
  getContentPopupIdSendingImages,
  getTextPopupIdLayout
} from '../../utils/textosPopups';

import store from '../../store';
import * as actionGeneral from '../../action/general';
import { getItemId } from '../../selector/index';

import PropTypes, { oneOfType } from 'prop-types';
import React, { Component } from 'react';

import Button from '../button';
import ErrorAlert from '../error-alert';
import Loader from '../loader';
import ScreenSizeErrorIcon from '../../asset/icone_erro_screensize.png';
import SubmitComplete from '../../asset/icone_concluido.png';
import { THEME_CLASS_ORANGE } from '../../constant/button';
import TextSpinner from '../text-spinner';
import getTitle from '../../constant/title';
import { postItem, postLog } from '../../api';
import { warnClose } from '../../app';
import { isMobile } from 'react-device-detect';
import { imprimirTexto } from '../../utils/impressaoTela';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { getTipoPag } from '../../utils/textosVariaveis';
import { notFixedWidthPopup } from '../../utils/separadorProdutos';

const formatPage = page =>
  page > 9 || typeof page === 'string' ? page : `0${page}`;

const updateMsg = arr => {
  let msg = '';
  arr.forEach((el, i) =>
    i
      ? i === arr.length - 1
        ? (msg += ` e ${formatPage(el)}`)
        : (msg += `, ${formatPage(el)}`)
      : (msg += `${formatPage(el)}`)
  );
  return msg;
};

const restartProduct = () => {
  const state = store.getState();
  const id = getItemId(state);
  store.dispatch(actionGeneral.startImageLoop());
  store.dispatch(actionGeneral.startMetadataLoop(id));
  store.dispatch(actionGeneral.sagaSetPageIndex(0));
};

class Popup extends Component {
  state = {
    email: '',
    error: false,
    warning: false,
    msg: ''
  };

  updateEmail = e =>
    this.setState({
      email: e.target.value,
      error: false,
      warning: false
    });

  checkEmail = async () => {
    const { email } = this.state;
    const re = /\S+@\S+\.\S+/;
    if (re.test(String(email).toLowerCase())) {
      const { itemId } = this.props;
      const postUrl = `/api/items/${itemId}/contact`;
      let error = false;
      await postItem(postUrl, { email }).catch(() => (error = true));
      if (error) {
        this.setState({
          warning: true,
          msg:
            'Erro de conexão de internet. Verifique sua rede e tente novamente'
        });
      } else {
        this.props.updatePopup(POPUP_ID_NONE, false, '');
        if (this.props.prodType === 'mask') {
          this.props.updateTutorialStatus('PASSO');
          this.props.setStateLegenda('INTRODUCAO');
        } else if (this.props.prodType === 'dog-collar') {
          this.props.updateTutorialStatus('ESCOLHER-TAMANHO-COLEIRA');
        } else setTimeout(() => this.props.updateNav(NAV_IMAGE_SELECTED), 300);
      }
    } else {
      this.setState({ error: true, msg: 'O e-mail digitado não é válido' });
    }
  };

  render() {
    const {
      display,
      overlay,
      id,
      bookHaveCover,
      msg,
      isMetadataUploaded,
      imageLoading,
      updatePopup,
      setPageIndex,
      setLastPage,
      updateNav,
      inputFileChangeHandler,
      addPages,
      removeImagesFromBook,
      toggleRemovePages,
      removePages,
      setLayout,
      updateLayoutFilterApplyMethod,
      updateLayoutFilterSide,
      deleteAllImages,
      updateBookOverviewExpanded,
      submitOrder,
      updateTutorialStatus,
      prodType,
      setStateLegenda,
      isModoSobFivela,
      toggleModoSobFivela
    } = this.props;

    const coverInc = bookHaveCover ? 1 : 0;
    let popupContent,
      pageIndex = 0,
      msgPhoto = '',
      msgText = '';
    const btnStyle = {
      width: '100%',
      padding: '0px 10px 2px',
      margin: '0',
      fontWeight: 'normal',
      fontSize: '22px'
    };

    if (msg.photo && msg.photo.length) {
      msgPhoto = updateMsg(msg.photo);
      pageIndex =
        typeof msg.photo[0] === 'string'
          ? 0
          : parseInt(msg.photo[0] / 2) + coverInc;
    }

    if (msg.text && msg.text.length) {
      msgText = updateMsg(msg.text);
      const textIdx = parseInt(msg.text[0] / 2) + coverInc;
      pageIndex =
        typeof msg.text[0] === 'string' ? 0 : Math.min(textIdx, pageIndex);
    }

    switch (id) {
      case POPUP_ID_SUBMITING:
      case POPUP_ID_LOADING:
        popupContent = (
          <div className="popup-default-styles popup-loading-submiting">
            <Loader />
            <p className="title">
              <TextSpinner msg={msg} />
            </p>
          </div>
        );
        break;
      case POPUP_ID_ERROR_LOAD:
        popupContent = <ErrorAlert errMsg={msg} />;
        break;
      case POPUP_ID_MUTIPLE_TABS:
      case POPUP_ID_MAX_USER_SPACE:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div
              className="message"
              style={{ marginTop: '17px', marginBottom: 0 }}
            >
              {imprimirTexto(msg.content)}
            </div>
          </div>
        );
        break;
      case POPUP_ID_MAX_IMAGE_COUNT:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div
              className="message"
              style={{ marginTop: '17px', marginBottom: 0 }}
            >
              {imprimirTexto(msg.content)}
            </div>
            <div className="buttons" style={{ marginTop: '30px' }}>
              <Button
                id="botao-cancelar-popup"
                label="Ok, entendi"
                style={{ ...btnStyle, width: '214px' }}
                theme={THEME_CLASS_ORANGE}
                clickHandler={() => {
                  updatePopup(POPUP_ID_NONE, false, '');
                }}
              />
            </div>
          </div>
        );
        break;
      case POPUP_ID_SENDING_METADATA:
        popupContent = (
          <div className="popup-default-styles">
            <Loader min />
            <div className="submiting">
              <h4>{getContentPopupIdSendingImages()}</h4>
              <p>
                Por favor, não feche esta página até o envio ser totalmente
                concluído.
              </p>
            </div>
          </div>
        );
        break;
      case POPUP_ID_SENDING_IMAGES:
      case POPUP_ID_SENDING_IMAGES_COMPLETE:
        const msgLoadFinished = {
          title: `Suas imagens foram enviadas com sucesso!`,
          content: [`Envio concluído!`, `Agora você já pode fechar a página.`]
        };
        imageLoading === 100 && id === POPUP_ID_SENDING_IMAGES
          ? isMetadataUploaded
            ? updatePopup(
                POPUP_ID_SENDING_IMAGES_COMPLETE,
                true,
                msgLoadFinished
              )
            : (popupContent = (
                <div className="popup-default-styles">
                  <Loader min />
                  <div className="submiting">
                    <h4>{getContentPopupIdSendingImages()}</h4>
                    <p>
                      Por favor, não feche esta página até o envio ser
                      totalmente concluído.
                    </p>
                  </div>
                </div>
              ))
          : (popupContent = (
              <div className="popup-default-styles">
                {id === POPUP_ID_SENDING_IMAGES ? (
                  <Loader min />
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={SubmitComplete}
                      alt="submit_image_complete"
                      className="submit_image_complete"
                    />
                  </div>
                )}
                <div className="submiting">
                  <h3>{imageLoading}%</h3>
                  <h4>{msg.title}</h4>
                  {msg.content.map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                </div>
              </div>
            ));
        break;
      case POPUP_SCREEN_SIZE_ERROR:
        popupContent = (
          <div className="popup-default-styles screen-error">
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <img src={ScreenSizeErrorIcon} alt="Erro resolução imagem"></img>
            </div>
            <h3>
              {imprimirTexto(
                isMobile
                  ? POPUP_SCREEN_SIZE_ERROR_H3_MOBILE
                  : POPUP_SCREEN_SIZE_ERROR_H3
              )}
            </h3>
            <div className="message">
              <p>
                {isMobile
                  ? POPUP_SCREEN_SIZE_ERROR_MESSAGE_MOBILE
                  : POPUP_SCREEN_SIZE_ERROR_MESSAGE}
              </p>
            </div>
          </div>
        );
        break;
      case POPUP_FIREFOX_ANONIMO:
        popupContent = (
          <div className="popup-default-styles screen-error">
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <img src={ScreenSizeErrorIcon} alt="Erro resolução imagem"></img>
            </div>
            <h3>{POPUP_FIREFOX_ANONIMO_TITLE}</h3>
            <div className="message">{POPUP_FIREFOX_ANONIMO_MESSAGE()}</div>
          </div>
        );
        break;
      case POPUP_ID_REMOVE_ALL_IMAGES:
        popupContent = (
          <div className="popup-default-styles">
            <h3>Deseja remover todas as suas imagens?</h3>
            <div className="message">
              <p>
                Esta ação irá remover todas as imagens do projeto, incluindo as
                do {getTitle()}
              </p>
              <p>
                Para escolher novas imagens você deverá usar o botão adicionar
                localizado no menu fotos
              </p>
            </div>
            <div>
              <Button id="botao-acao-popup" label="Remover imagens" />
              <Button id="botao-cancelar-popup" label="Voltar ao projeto" />
            </div>
          </div>
        );
        break;
      case POPUP_ID_LAYOUT:
        const popupIdLayoutText = getTextPopupIdLayout(msgPhoto, msgText);
        popupContent = (
          <div className="popup-default-styles">
            <h3>{popupIdLayoutText[0]}</h3>
            {!!msg.photo.length && (
              <div className="message">
                <p>{popupIdLayoutText[1]}</p>
                <p>{popupIdLayoutText[2]}</p>
              </div>
            )}
            {!!msg.text.length && (
              <div className="message">
                <p>{popupIdLayoutText[3]}</p>
                <p>{popupIdLayoutText[4]}</p>
              </div>
            )}
            <div className="buttons">
              <Button
                id="botao-cancelar-popup"
                label="Cancelar"
                style={{ ...btnStyle, marginRight: '10px' }}
                clickHandler={() => updatePopup(POPUP_ID_NONE, false, '')}
              />
              <Button
                id="botao-acao-popup"
                label="Remover conteúdo"
                style={{ ...btnStyle, marginLeft: '10px' }}
                theme={THEME_CLASS_ORANGE}
                clickHandler={() => {
                  setPageIndex(pageIndex);
                  updatePopup(POPUP_ID_NONE, false, '');
                }}
              />
            </div>
          </div>
        );
        break;
      case POPUP_ID_FILL_PAGES_PHOTOS_NEEDED:
      case POPUP_ID_UNABLE_LOAD_IMAGE:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div className="message">
              <div>
                {msg.content.map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </div>
            </div>

            <div className="buttons">
              <Button
                id="botao-cancelar-popup"
                label={msg.btnLabel.left}
                style={{ ...btnStyle, marginRight: '10px' }}
                clickHandler={() => {
                  restartProduct();
                  updatePopup(POPUP_ID_NONE, false, '');
                }}
              />
              <input
                type="file"
                name="image-input-file"
                id="image-input-file"
                multiple
                accept="image/*"
                onChange={e => {
                  inputFileChangeHandler(e);
                  restartProduct();
                  updatePopup(POPUP_ID_NONE, false, '');
                }}
                className="input-file"
              />
              <label
                data-tip="Coloca mais imagens na mesa"
                className="label-add-photo-popup"
                htmlFor="image-input-file"
                style={{ paddingTop: '2px' }}
              >
                {msg.btnLabel.right}
              </label>
            </div>
          </div>
        );
        break;
      case POPUP_IDENTIFY:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div className="message">{msg.content}</div>
            <div className="input-field">
              {(this.state.error || this.state.warning) && (
                <span className="input-error">*{this.state.msg}</span>
              )}
              <input
                type="email"
                name="email"
                id="popup-email"
                placeholder="E-mail"
                data-error={this.state.error}
                data-warning={this.state.warning}
                onChange={this.updateEmail}
              />
            </div>
            <div className="buttons">
              <Button
                id="botao-cancelar-popup"
                label={msg.btnLabel.left}
                style={{ ...btnStyle, marginRight: '10px' }}
                clickHandler={() => {
                  updatePopup(POPUP_ID_NONE, false, '');
                  if (prodType === 'mask') {
                    updateTutorialStatus('PASSO');
                    setStateLegenda('INTRODUCAO');
                  } else if (prodType === 'dog-collar') {
                    updateTutorialStatus('ESCOLHER-TAMANHO-COLEIRA');
                  } else
                    setTimeout(
                      () => this.props.updateNav(NAV_IMAGE_SELECTED),
                      300
                    );
                }}
              />
              <Button
                id="botao-acao-popup"
                label={msg.btnLabel.right}
                style={{ ...btnStyle, marginLeft: '10px' }}
                theme={THEME_CLASS_ORANGE}
                clickHandler={this.checkEmail}
              />
            </div>
          </div>
        );
        break;
      case POPUP_IDENTIFY_EMAIL:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div className="message">{msg.content}</div>
            <div className="input-field">
              {(this.state.error || this.state.warning) && (
                <span className="input-error">*{this.state.msg}</span>
              )}
              <input
                type="email"
                name="email"
                id="popup-email"
                placeholder="E-mail"
                data-error={this.state.error}
                data-warning={this.state.warning}
                onChange={this.updateEmail}
              />
            </div>
            <div className="buttons">
              <Button
                id="botao-acao-popup"
                label={msg.btnLabel.right}
                style={{ ...btnStyle, marginLeft: '234px', width: '214px' }}
                theme={THEME_CLASS_ORANGE}
                clickHandler={this.checkEmail}
              />
            </div>
          </div>
        );
        break;
      case POPUP_ID_FILL_PAGES_PAGES_NEEDED:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div className="message">
              <div>
                {msg.content.map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </div>
            </div>
            <div className="buttons">
              <Button
                id="botao-cancelar-popup"
                label={msg.btnLabel.left}
                style={{
                  ...btnStyle,
                  marginRight: '10px',
                  width: msg.btnLabel.right ? '100%' : '214px'
                }}
                clickHandler={() => updatePopup(POPUP_ID_NONE, false, '')}
              />
              {msg.btnLabel.right && (
                <Button
                  id="botao-acao-popup"
                  label={msg.btnLabel.right}
                  style={{ ...btnStyle, marginLeft: '10px' }}
                  theme={THEME_CLASS_ORANGE}
                  clickHandler={() => {
                    addPages();
                    updatePopup(POPUP_ID_NONE, false, '');
                  }}
                />
              )}
            </div>
          </div>
        );
        break;
      case POPUP_ID_PAGE_NOT_COMPLETE_FILLED:
      case POPUP_ID_POSTER_NOT_COMPLETE_FILLED:
      case POPUP_ID_REVISAR_MUDACA_ORIENTACAO:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div className="message">
              <div>
                {msg.content.map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </div>
            </div>
            <div className="buttons">
              {msg.btnLabel.left && (
                <Button
                  id="botao-cancelar-popup"
                  label={msg.btnLabel.left}
                  style={{ ...btnStyle, marginRight: '10px' }}
                  clickHandler={() => {
                    updatePopup(
                      POPUP_ID_FINISH_BOOK,
                      true,
                      POPUP_ID_FINISH_BOOK_TEXT()
                    );
                    const buttonFocus = document.getElementById(
                      'botao-cancelar-popup'
                    );
                    if (buttonFocus) buttonFocus.blur();
                  }}
                />
              )}
              <Button
                id={
                  msg.btnLabel.left
                    ? 'botao-acao-popup'
                    : 'botao-cancelar-popup'
                }
                label={msg.btnLabel.right}
                style={{
                  ...btnStyle,
                  width: msg.btnLabel.left ? '100%' : '214px'
                }}
                theme={THEME_CLASS_ORANGE}
                clickHandler={() => {
                  if (prodType === 'deck-cards') {
                    if (
                      (!isModoSobFivela && msg.proximaPaginaVazia > 1) ||
                      (isModoSobFivela && msg.proximaPaginaVazia <= 1)
                    )
                      toggleModoSobFivela();
                  } else setPageIndex(msg.proximaPaginaVazia);
                  updatePopup(POPUP_ID_NONE, false, '');
                }}
              />
            </div>
          </div>
        );
        break;
      case POPUP_ID_IMAGE_USED:
      case POPUP_ID_CLEAR_BOOK:
      case POPUP_ID_REMOVE_IMAGES:
      case POPUP_ID_REMOVE_PAGES:
      case POPUP_ID_TOGGLE_REMOVE_PAGES:
      case POPUP_ID_CONFIRM_REMOVE_PAGES:
      case POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT:
      case POPUP_CONFIRM_UPDATE_APPLY_METHOD:
      case POPUP_ID_FINISH_BOOK:
      case POPUP_ID_ABANDON_PROJECT:
        popupContent = (
          <div className="popup-default-styles">
            <h3>{msg.title}</h3>
            <div className="message">
              <div>
                {msg.content.map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </div>
            </div>

            <div className="buttons">
              <Button
                id="botao-cancelar-popup"
                label={msg.btnLabel.left}
                style={{ ...btnStyle, marginRight: '10px' }}
                clickHandler={() => {
                  switch (id) {
                    case POPUP_ID_CONFIRM_REMOVE_PAGES:
                      toggleRemovePages();
                      updatePopup(POPUP_ID_NONE, false, '');
                      break;
                    case POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT:
                      updatePopup(POPUP_ID_NONE, false, '');
                      setTimeout(() => updateNav(NAV_LAYOUTS_SELECTED), 1);
                      break;
                    case POPUP_CONFIRM_UPDATE_APPLY_METHOD:
                      updatePopup(POPUP_ID_NONE, false, '');
                      setTimeout(() => updateNav(NAV_LAYOUTS_SELECTED), 1);
                      break;
                    default:
                      updatePopup(POPUP_ID_NONE, false, '');
                      break;
                  }
                }}
              />
              <Button
                id="botao-acao-popup"
                label={msg.btnLabel.right}
                style={{
                  ...btnStyle,
                  marginLeft: '10px',
                  display: msg.btnLabel.right ? 'initial' : 'none'
                }}
                theme={THEME_CLASS_ORANGE}
                clickHandler={() => {
                  switch (id) {
                    case POPUP_ID_IMAGE_USED:
                      setPageIndex(msg.page + coverInc);
                      break;
                    case POPUP_ID_CLEAR_BOOK:
                      removeImagesFromBook();
                      break;
                    case POPUP_ID_REMOVE_IMAGES:
                      deleteAllImages();
                      break;
                    case POPUP_ID_REMOVE_PAGES:
                      setPageIndex(
                        parseInt(
                          prodType === 'mask'
                            ? msg.pages[0] - 1
                            : msg.pages[0] / 2
                        ) + coverInc
                      );
                      updateBookOverviewExpanded(false);
                      break;
                    case POPUP_ID_TOGGLE_REMOVE_PAGES:
                      toggleRemovePages();
                      break;
                    case POPUP_ID_CONFIRM_REMOVE_PAGES:
                      updatePopup(POPUP_ID_LOADING, true, 'Removendo');
                      removePages();
                      return;
                    case POPUP_ID_UNABLE_SET_LAST_PAGE_LAYOUT:
                      setLayout(msg.layout, true);
                      setTimeout(() => {
                        updateLayoutFilterApplyMethod(
                          `somente ${getTipoPag()} atual`
                        );
                        updateLayoutFilterSide('LEFT');
                        updateNav(NAV_NONE_SELECTED);
                        setLastPage();
                        updateNav(NAV_LAYOUTS_SELECTED);
                      }, 5);
                      break;
                    case POPUP_CONFIRM_UPDATE_APPLY_METHOD:
                      setTimeout(() => {
                        updateNav(NAV_LAYOUTS_SELECTED);
                        updateLayoutFilterApplyMethod(msg.type);
                      }, 1);
                      break;
                    case POPUP_ID_ABANDON_PROJECT:
                      window.removeEventListener('beforeunload', warnClose);
                      break;
                    case POPUP_ID_FINISH_BOOK:
                      window.removeEventListener('beforeunload', warnClose);
                      postLog(
                        `Finalizar produto - Clique Finalizar (Image uploaded: ${imageLoading ||
                          0}%)`
                      );
                      if (imageLoading !== 100) {
                        const page = window.open('', '_blank');
                        page.document.write(
                          `<div style="
                            width: 100%; 
                            display: flex; 
                            justify-content: center;  
                            color: #666666; 
                            font-family: Lato, sans-serif; 
                            font-size: 18px; 
                            font-weight: bold;
                            margin-top: 175px;"
                          >
                            Carregando seu carrinho de compras...
                          </div>`
                        );
                        return submitOrder(page);
                      }
                      return submitOrder();
                    default:
                      break;
                  }
                  updatePopup(POPUP_ID_NONE, false, '');
                }}
              />
            </div>
          </div>
        );
        break;
      case POPUP_ID_NONE:
      default:
        break;
    }
    if (display) {
      return (
        <div className={`popup-manager ${overlay ? 'overlay' : ''}`}>
          <KeyboardEventHandler
            handleKeys={['left', 'right']}
            handleFocusableElements={true}
            onKeyEvent={key => {
              if (id !== POPUP_ID_SUBMITING && id !== POPUP_ID_LOADING)
                if (key === 'left') {
                  const buttonFocus = document.getElementById(
                    'botao-cancelar-popup'
                  );
                  if (buttonFocus) buttonFocus.focus();
                } else if (key === 'right') {
                  const buttonFocus = document.getElementById(
                    'botao-acao-popup'
                  );
                  if (buttonFocus) buttonFocus.focus();
                }
            }}
          />
          <div
            className={`container ${
              notFixedWidthPopup(id) ? 'not-fixed-width' : ''
            }`}
          >
            {popupContent || false}
          </div>
        </div>
      );
    }

    return false;
  }
}

Popup.propTypes = {
  itemId: PropTypes.number,
  display: PropTypes.bool.isRequired,
  overlay: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  msg: oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isMetadataUploaded: PropTypes.bool.isRequired,
  imageLoading: PropTypes.number.isRequired,
  updatePopup: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  setLastPage: PropTypes.func.isRequired,
  updateNav: PropTypes.func.isRequired,
  inputFileChangeHandler: PropTypes.func.isRequired,
  addPages: PropTypes.func.isRequired,
  removeImagesFromBook: PropTypes.func.isRequired,
  toggleRemovePages: PropTypes.func.isRequired,
  removePages: PropTypes.func.isRequired,
  setLayout: PropTypes.func.isRequired,
  updateLayoutFilterApplyMethod: PropTypes.func.isRequired,
  updateLayoutFilterSide: PropTypes.func.isRequired,
  deleteAllImages: PropTypes.func.isRequired,
  updateBookOverviewExpanded: PropTypes.func.isRequired,
  submitOrder: PropTypes.func.isRequired,
  updateTutorialStatus: PropTypes.func.isRequired,
  prodType: PropTypes.string,
  setStateLegenda: PropTypes.func.isRequired,
  isModoSobFivela: PropTypes.bool.isRequired,
  toggleModoSobFivela: PropTypes.func.isRequired
};

Popup.defaultProps = {
  overlay: false,
  display: false
};

export default Popup;
